._bg-1n9dn6{background-color:var(--primaryDark);}
._ta-center{text-align:center;}
._col-fbw504{color:var(--light);}
._tt-1b7l12b{text-transform:var(--f-tr-10);}
._ff-4yewjq{font-family:var(--f-fa);}
._fow-1cfkkj7{font-weight:var(--f-we-10);}
._ls-1dnk403{letter-spacing:var(--f-yw-10);}
._fos-1all9bv{font-size:var(--f-si-10);}
._lh-17a9wki{line-height:var(--f-li-10);}
._mt-0px{margin-top:0px;}
._mr-0px{margin-right:0px;}
._mb-0px{margin-bottom:0px;}
._ml-0px{margin-left:0px;}
._ussel-auto{user-select:auto;-webkit-user-select:auto;}
._ww-break-word{word-wrap:break-word;}
._bxs-border-box{box-sizing:border-box;}
._dsp-inline{display:inline;}
._als-center{align-self:center;}
._mt-lrpiu9{margin-top:var(--space-11);}
._mr-lrpiu9{margin-right:var(--space-11);}
._mb-lrpiu9{margin-bottom:var(--space-11);}
._ml-lrpiu9{margin-left:var(--space-11);}
._w-qnlo3j{width:var(--size-21);}
._bbw-1px{border-bottom-width:1px;}
._mah-0px{max-height:0px;}
._h-0px{height:0px;}
._fg-1{flex-grow:1;}
._fs-1{flex-shrink:1;}
._fb-auto{flex-basis:auto;}
._btw-1aj14bf{border-top-width:var(--space-6);}
._brw-1aj14bf{border-right-width:var(--space-6);}
._blw-1aj14bf{border-left-width:var(--space-6);}
._btc-qrc2f2{border-top-color:var(--accent);}
._brc-qrc2f2{border-right-color:var(--accent);}
._bbc-qrc2f2{border-bottom-color:var(--accent);}
._blc-qrc2f2{border-left-color:var(--accent);}
._fd-column{flex-direction:column;}
._miw-0px{min-width:0px;}
._mih-0px{min-height:0px;}
._pos-relative{position:relative;}
._dsp-flex{display:flex;}
._ai-stretch{align-items:stretch;}
._bbs-solid{border-bottom-style:solid;}
._bts-solid{border-top-style:solid;}
._bls-solid{border-left-style:solid;}
._brs-solid{border-right-style:solid;}
._transform-n24na2{transform:translateY(-0.5px);}
._pt-1aj14ca{padding-top:var(--space-7);}
._pr-1aj14ca{padding-right:var(--space-7);}
._pb-1aj14ca{padding-bottom:var(--space-7);}
._pl-1aj14ca{padding-left:var(--space-7);}
._maw-qnlou8{max-width:var(--size-31);}
._lh-3or5x5{line-height:var(--f-li-8);}
._fos-3slq2o{font-size:var(--f-si-8);}
._ls-5wa{letter-spacing:$.5;}