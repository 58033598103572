._fd-column{flex-direction:column;}
._miw-0px{min-width:0px;}
._mih-0px{min-height:0px;}
._pos-relative{position:relative;}
._bxs-border-box{box-sizing:border-box;}
._fb-auto{flex-basis:auto;}
._dsp-flex{display:flex;}
._fs-0{flex-shrink:0;}
._ai-stretch{align-items:stretch;}
._pt-9px{padding-top:9px;}
._pr-9px{padding-right:9px;}
._pb-9px{padding-bottom:9px;}
._pl-9px{padding-left:9px;}
._fd-row{flex-direction:row;}
._col-5745x7{color:var(--text);}
._fos-32px{font-size:32px;}
._fow-700{font-weight:700;}
._pl-12px{padding-left:12px;}
._mt-0px{margin-top:0px;}
._mr-0px{margin-right:0px;}
._mb-0px{margin-bottom:0px;}
._ml-0px{margin-left:0px;}
._ww-break-word{word-wrap:break-word;}
._ff-4yewjq{font-family:var(--f-fa);}
._dsp-inline{display:inline;}
._pt-60px{padding-top:60px;}
._pt-lrpixp{padding-top:var(--space-15);}
._pb-lrpixp{padding-bottom:var(--space-15);}
._ai-center{align-items:center;}
@media screen and (max-width: 800px) { :root:root ._fd-_sm_column{flex-direction:column;} }
@media screen and (min-width: 801px) { :root:root:root ._fd-_gtSm_1b2fkz3{flex-direction:row-reverse;} }
._jc-center{justify-content:center;}
@media screen and (max-width: 800px) { :root:root ._mb-_sm_lrpiu9{margin-bottom:var(--space-11);} }
@media screen and (min-width: 801px) { :root:root:root ._fd-_gtSm_row{flex-direction:row;} }
._pr-1aj14e0{padding-right:var(--space-9);}
._pb-1aj14e0{padding-bottom:var(--space-9);}
._pl-1aj14e0{padding-left:var(--space-9);}
._fos-1all9bv{font-size:var(--f-si-10);}
._ta-center{text-align:center;}
._tt-3tb9kn{text-transform:var(--f-tr-9);}
._fow-3uqciv{font-weight:var(--f-we-9);}
._ls-3w5fh3{letter-spacing:var(--f-yw-9);}
._lh-3or5y0{line-height:var(--f-li-9);}
._ussel-auto{user-select:auto;-webkit-user-select:auto;}
._pt-lrpiu9{padding-top:var(--space-11);}
._pr-lrpiu9{padding-right:var(--space-11);}
._pl-lrpiu9{padding-left:var(--space-11);}
._w-10011{width:100%;}
@media screen and (min-width: 801px) { :root:root ._w-_gtSm_3011{width:30%;} }
._pt-1aj14ca{padding-top:var(--space-7);}
._pb-1aj14ca{padding-bottom:var(--space-7);}
._fos-3slq3j{font-size:var(--f-si-9);}
._tt-3tb9js{text-transform:var(--f-tr-8);}
._fow-3uqci0{font-weight:var(--f-we-8);}
._ls-3w5fg8{letter-spacing:var(--f-yw-8);}
._lh-3or5x5{line-height:var(--f-li-8);}
._fos-3slq2o{font-size:var(--f-si-8);}
._maw-400px{max-width:400px;}
._maw-qnlov3{max-width:var(--size-32);}
._w-9011{width:90%;}